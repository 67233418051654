import React from "react";
import ReactDOM from "react-dom/client";

import ChooseLang from "../../components/header/Header";
import GlobalStateProvider from "../../components/store/store";
import Stripe from "../../components/stripe/Stripe";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>
);

function App() {
    return <div className="page-reset">
        <GlobalStateProvider>
            <ChooseLang />
            <Stripe />
        </GlobalStateProvider>
    </div>;
}