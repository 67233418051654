import React, { useState, useEffect, ChangeEvent } from "react";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
// import { Account } from "./account";
import { Payement } from "./payement";
import { FaTrashAlt } from "react-icons/fa";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51IRfzIEnO6IFZ4Ya1uAqAPMrDf0Vb9rpZGiDtBuSEQOVYZVR5LUwnrH3tRA0kPVDFyEM1byznQR6xFsKunC7e9jG00erZcNcBi");

interface Response {
    clientSecret?: string;
    // account?: Account;
    payements: Payement[];
    products: ProductPrice[];
}

interface Article {
    text: string;
    price: number;
}

interface Currency {
    code: string;
    name: string;
    symbol: string;
}

interface Price {
    gross_amount: number;
    net_amount: number;
    tax_rate: number;
    currency: Currency;
}

interface ProductPrice {
    id: string;
    code: string;
    name: string;
    price: Price;
}

export default function Stripe() {
    const [clientSecret, setClientSecret] = useState("");
    const [payements, setPayements] = useState([] as Payement[]);
    const [selectedPayement, setSelectedPayement] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState("");
    const [products, setProducts] = useState([] as ProductPrice[]);

    const name = window.location.pathname;
    function loadData(force: Boolean = false, index: number = -1) {
        setError("");
        setLoaded(false);

        console.log(`index: ${index}`);
        console.log(`payement: %o`, payements[index]);

        fetch(`/api/v1/payements${name}${force || index > 0 ? "?" : ""}${force ? "force=true" : ""}${index >= 0 ? "&payement_id=" + payements[index].id : ""}`, { method: "GET" })
            .then((res) => {
                if (res.status == 404) {
                    console.log(`got error 1:`, res);
                    setError("Dossier non trouvé");
                    return;
                }
                if (res.status != 200) {
                    console.log(`got error 2:`, res);
                    setError(`Erreur ${res.status}`);
                    return;
                }
                res.json()
                    .then((data: Response) => {
                        console.log(data);
                        if (data.clientSecret)
                            setClientSecret(data.clientSecret);
                        if (data.payements && data.payements.length > 0) {
                            setPayements(data.payements);
                            if (selectedPayement >= data.payements.length) {
                                setSelectedPayement(0);
                            }
                        }
                        if (data.products && data.products.length > 0) {
                            setProducts(data.products);
                        }
                        setLoaded(true);
                    })
                    .catch((err) => {
                        console.log(`got error 3:`, err);
                        setError(err);
                    })
                    ;
            })
            .catch((err) => {
                console.log(`got error 4:`, err);
                setError(err)
            })
            ;
    }

    useEffect(loadData, []);

    // ---- BEGIN: Stripe stuff ----

    const appearance: Appearance = {
        theme: 'stripe',
    };
    const options: StripeElementsOptions = {
        clientSecret,
        appearance,
    };

    // ---- END: Stripe stuff ----

    // ------------------

    function updateArticle(payement_id: Number, article_id: Number, key: String, value: String, reload: Boolean = false) {
        const myHeaders = new Headers();
        myHeaders.set('Content-Type', 'application/json');
        const data = { payement_id, article_id, name: key, value };

        const myInit: RequestInit = {
            method: 'PUT',
            headers: myHeaders,
            mode: "cors",
            cache: "default",
            body: JSON.stringify(data),
        };

        fetch(`/api/v1/payements${name}`, myInit)
            .then((res) => {
                if (res.status != 200 && res.status != 204) {
                    console.log(`got error 2:`, res);
                    setError(`Erreur ${res.status}`);
                    return;
                }
                if (reload) {
                    loadData()
                }
            })
            .catch((err) => {
                console.log(`got error 4:`, err);
                setError(err)
            })
            ;
    }

    // ------------------

    const onSealPayement = (evt: React.MouseEvent<HTMLButtonElement>, index: number) => {
        alert('on seal payement');
        evt.preventDefault();
        const target = evt.target as HTMLButtonElement;
        updateArticle(payements[index]?.id, 0, "seal", "true", true);
    }

    const onUnSealPayement = (evt: React.MouseEvent<HTMLButtonElement>, index: number) => {
        alert('on unseal payement');
        evt.preventDefault();
        const target = evt.target as HTMLButtonElement;
        updateArticle(payements[index]?.id, 0, "seal", "false", true);
    }

    const onChangeText = (evt: React.ChangeEvent<HTMLInputElement>, index: number) => {
        evt.preventDefault();
        const target = evt.target as HTMLInputElement;
        const tmp = [...payements];
        if (tmp[selectedPayement]?.articles[index]) {
            tmp[selectedPayement].articles[index].designation = target.value;
            setPayements(tmp);
            updateArticle(tmp[selectedPayement]?.id, tmp[selectedPayement]?.articles[index]?.id, "designation", target.value);
        }
    }

    const onChangePrice = (evt: React.ChangeEvent<HTMLInputElement>, index: number) => {
        evt.preventDefault();
        const target = evt.target as HTMLInputElement;
        const price = parseFloat(target.value);
        if (!isNaN(price)) {
            const tmp = [...payements];
            if (tmp[selectedPayement]?.articles[index]) {
                tmp[selectedPayement].articles[index].price = price;
                setPayements(tmp);
                updateArticle(tmp[selectedPayement]?.id, tmp[selectedPayement]?.articles[index]?.id, "price", target.value);
            }
        }
    }

    // ------------------

    const onRemoveArticle = (evt: React.MouseEvent<HTMLButtonElement>, index: number) => {
        evt.preventDefault();

        const tmp = [...payements];
        const payement_id = tmp[selectedPayement]?.id;
        const article_id = tmp[selectedPayement]?.articles[index]?.id;

        const myInit: RequestInit = {
            method: 'DELETE',
            mode: "cors",
            cache: "default",
        };

        fetch(`/api/v1/payements${name}?payement_id=${payement_id}&article_id=${article_id}`, myInit)
            .then((res) => {
                if (res.status != 200 && res.status != 204) {
                    console.log(`got error 2:`, res);
                    setError(`Erreur ${res.status}`);
                    return;
                }
                tmp[selectedPayement]?.articles?.splice(index, 1);
                setPayements(tmp);
            })
            .catch((err) => {
                console.log(`got error 4:`, err);
                setError(err)
            })
            ;
    }

    const onAddArticle = (evt: React.MouseEvent<HTMLButtonElement>, text: string, price: number) => {
        evt.preventDefault();

        const myHeaders = new Headers();
        myHeaders.set('Content-Type', 'application/json');
        const payement_id = payements[selectedPayement]?.id;
        const data = { payement_id, text, price };

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: "cors",
            cache: "default",
            body: JSON.stringify(data),
        };

        fetch(`/api/v1/payements${name}`, myInit)
            .then((res) => {
                if (res.status != 200) {
                    console.log(`got error 2:`, res);
                    setError(`Erreur ${res.status}`);
                    return;
                }
                res.json()
                    .then(json => {
                        const tmp = [...payements];
                        tmp[selectedPayement]?.articles?.push({
                            id: json.id,
                            designation: text,
                            price: price,
                        });
                        setPayements(tmp);
                    })
                    .catch((err) => {
                        console.log(`got error 2:`, err);
                        setError(err)
                    })
                    ;
            })
            .catch((err) => {
                console.log(`got error 4:`, err);
                setError(err)
            })
            ;
    }

    // ------------------

    const onChangePayement = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPayement(parseInt(evt.target.value, 10));
    }

    const onForceReload = (evt: React.MouseEvent<HTMLButtonElement>, index: number = -1) => {
        console.log(`onForceReload: index=${index} selectedPayement=${selectedPayement}`);
        loadData(true, index)
    }

    // ---- BEGIN: Compute article price ----

    let totalHT = 0;
    payements[selectedPayement]?.articles?.forEach(article => {
        totalHT += article.price;
    });
    const tva = totalHT * 0.20;
    const totalTTC = totalHT * 1.20;

    // ---- END: Compute article price ----

    console.log(products);

    return (
        <div className="container">
            {/* <div className="row mt-3">
                <div className="col-4 fw-bold">
                    error: {error}
                </div>
            </div> */}
            {loaded ? (
                <form>
                    {payements.length == 0 ? (
                        <div className="row mt-3">
                            <div className="col-1">
                            </div>
                            <div className="col-7">
                                Pas d'opportunité trouvée dans ce dossier
                            </div>
                            <div className="col-3 text-end">
                                {!payements[selectedPayement].sealed && (
                                    <button onClick={evt => onForceReload(evt, selectedPayement)}
                                        className="btn btn-sm btn-success">Mettre à jour depuis le CRM</button>
                                )}
                            </div>
                            <div className="col-1">
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="col-2 fw-bold">
                                    Nom
                                </div>
                                <div className="col-6">
                                    {payements.length > 1 ? (
                                        <select className="form-select" value={selectedPayement} onChange={onChangePayement}>
                                            {payements.map((payement, index) => {
                                                return (
                                                    <option value={index}>
                                                        {payement.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    ) : payements[0].name}
                                </div>
                                <div className="col-3 text-end">
                                    {!payements[selectedPayement].sealed && (
                                        <button onClick={evt => onForceReload(evt, selectedPayement)}
                                            className="btn btn-sm btn-success">Mettre à jour depuis le CRM</button>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="col-2 fw-bold">
                                    Email
                                </div>
                                <div className="col">
                                    {payements[selectedPayement]?.email}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="col-2 fw-bold">
                                    Adresse
                                </div>
                                <div className="col">
                                    {payements[selectedPayement]?.address?.address_line_1}
                                </div>
                            </div>
                            {payements[selectedPayement]?.address?.address_line_2 && (
                                <div className="row mt-3">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                    </div>
                                    <div className="col">
                                        {payements[selectedPayement]?.address?.address_line_2}
                                    </div>
                                </div>
                            )}
                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="col-2">
                                </div>
                                <div className="col">
                                    {payements[selectedPayement]?.address?.zip_code}
                                    &nbsp;
                                    {payements[selectedPayement]?.address?.city}
                                </div>
                            </div>
                            {payements[selectedPayement]?.address?.country && (
                                <div className="row mt-3">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                    </div>
                                    <div className="col">
                                        {payements[selectedPayement]?.address?.country}
                                    </div>
                                </div>
                            )}

                            <hr />

                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="fw-bold col-7">
                                    Désignation
                                </div>
                                <div className="fw-bold col-3 text-end">
                                    Prix &euro; H.T.
                                </div>
                                <div className="col-1">
                                </div>
                            </div>
                            {payements[selectedPayement]?.articles?.map((article, index) =>
                            (
                                <div className="row mt-3">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-7">
                                        {payements[selectedPayement].sealed ? (
                                            article.designation
                                        ) : (
                                            <input type="text" onChange={evt => onChangeText(evt, index)} className="border border-0 w-100" value={article.designation} />
                                        )}
                                    </div>
                                    <div className="col-3 text-end">
                                        {payements[selectedPayement].sealed ? (
                                            article.price.toFixed(2)
                                        ) : (
                                            <input type="text" onChange={evt => onChangePrice(evt, index)} className="border border-0 text-end" value={article.price.toFixed(2)} />
                                        )}
                                    </div>
                                    <div className="col-1">
                                        {!payements[selectedPayement].sealed && (
                                            <button onClick={evt => onRemoveArticle(evt, index)} className="btn btn-sm btn-danger">
                                                <FaTrashAlt />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )
                            )}

                            {!payements[selectedPayement].sealed && (
                                <div className="row mt-3">
                                    <div className="col-12 text-end">
                                        <div className="dropdown">
                                            <a className="btn btn-secondary btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                Ajouter un article
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                                                <>
                                                    {products.map(product => (
                                                        <li><button onClick={evt => onAddArticle(evt, product.name, product.price.net_amount)}
                                                            className="dropdown-item">{product.name} ({product.price.net_amount} &euro; H.T.)</button></li>
                                                    )
                                                    )}
                                                    {products.length > 0 && (
                                                        <li><hr className="dropdown-divider" /></li>
                                                    )}
                                                    <li><button onClick={evt => onAddArticle(evt, "Article personnalisé", 0.00)} className="dropdown-item">Article personnalisé</button></li>
                                                </>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="fw-bold col-7 text-end">
                                    Total &euro; H.T.
                                </div>
                                <div className="col-3 text-end text-secondary">
                                    {totalHT.toFixed(2)}
                                </div>
                                <div className="col-1">
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="fw-bold col-7 text-end">
                                    TVA (20 %) &euro;
                                </div>
                                <div className="col-3 text-end text-secondary">
                                    {tva.toFixed(2)}
                                </div>
                                <div className="col-1">
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-1">
                                </div>
                                <div className="fw-bold col-7 text-end">
                                    Total &euro; T.T.C.
                                </div>
                                <div className={totalHT == 0?"col-3 text-end text-danger":"col-3 text-end text-secondary"}>
                                    {totalTTC.toFixed(2)}
                                </div>
                                <div className="col-1">
                                </div>
                            </div>

                            <hr />

                            {clientSecret == "" ? (
                                <div className="row mt-3">
                                    <div className="col-12 text-end">
                                        {!payements[selectedPayement].sealed ? (
                                            <button disabled={totalHT == 0 ? true : false} onClick={evt => onSealPayement(evt, selectedPayement)} className="btn btn-sm btn-warning">Générer la page de paiement</button>
                                        ) : (
                                            <>
                                                <a href={"/" + payements[selectedPayement].guid}>Cliquez pour ouvrir la page de paiement</a>
                                                {payements[selectedPayement].email.trim() != "" && (
                                                    <>
                                                        &nbsp;
                                                        <button onClick={evt => { evt.preventDefault(); alert("Invitation non implémentée") }} className="btn btn-sm btn-info">Inviter le client</button>
                                                    </>
                                                )}
                                                &nbsp;
                                                <button onClick={evt => onUnSealPayement(evt, selectedPayement)} className="btn btn-sm btn-warning">Éditer</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <hr />

                                    <div className="row mt-3">
                                        {clientSecret && (
                                            <Elements options={options} stripe={stripePromise}>
                                                <CheckoutForm />
                                            </Elements>
                                        )}
                                    </div>

                                    <div className="row mt-3">
                                        &nbsp;
                                    </div>
                                </>
                            )}
                        </>
                    )}

                </form>
            ) : (
                <div className="row mt-3">
                    {error == "" ? (
                        <div className="col-4 fw-bold">
                            Chargement en cours ...
                        </div>
                    ) : (
                        <div className="col-4 text-danger">
                            {error}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}